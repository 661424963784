<template>
  <div class="inside-page-content">
    <div class="paper">
      <div class="top-search">
        <top-filter
          :typeList="typeList"
          :sortConfig="sortConfig"
          @handleSearchParams="handleSearch"
        ></top-filter>
      </div>
      <div class="pro-list">
        <div class="item" v-for="(t, i) in dataList" :key="i">
          <div class="product">
            <img :src="t.pictureUrl" class="img" />
            <div class="info">
              <div class="title">
                <div class="type">{{t.loanName}}</div>
                <div class="name">{{t.institutionName}}</div>
                <div class="icon">{{typeDic[t.guaranteeType]}}</div>
              </div>
              <div class="desc">
                <div class="desc-item">
                  <label class="info-text">参考年利率</label>
                  <label class="info-text">{{t.annualInterestRate}}</label>
                </div>
                <div class="desc-item">
                  <label class="info-text">最高贷款额度</label>
                  <label class="info-text">{{t.maxAmount}}万</label>
                </div>
                <div class="desc-item">
                  <label class="info-text">贷款期限</label>
                  <label class="info-text">{{loanLengthDic[t.loanLength]}}</label>
                </div>
                <div class="desc-item">
                  <label class="info-text">联系人</label>
                  <label class="info-text">{{t.contactName}}</label>
                </div>
                <div class="desc-item">
                  <label class="info-text">联系电话</label>
                  <label class="info-text">{{t.contactPhone}}</label>
                </div>
              </div>
            </div>
            <div class="more" @click="handleDetailClick(t.id)">查看详情</div>
          </div>
        </div>
        <div class="footer">
          <div class="left">共 {{total}} 条</div>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page.pageNum"
            layout="prev, pager, next"
            :page-size="8"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'loanList',
  components: {
    TopFilter
  },
  data() {
    return {
      typeDic: { 1: '抵质押类', 2: '信用类', 3: '担保类', 4: '组合类' },
      loanLengthDic: { 1: '6个月内(含)', 2: '12个月内(含)', 3: '36个月内(含)', 4: '60个月内(含)', 5: '60个月以上' },
      dataList: [],
      total: null,
      page: {
        pageNum: 1,
        pageSize: 8
      },
      queryInfo: {
        keywords: null,
        type: 1
      },
      current: 0,
      keyword: "",
      typeList: [
        {
          name: '贷款机构',
          code: 'institutionId',
          current: null,
          val: []
        },
        {
          name: '适用对象',
          code: 'applicableObject',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '个人',
              val: 1,
            },
            {
              name: '企业',
              val: 2,
            }
          ]
        },
        {
          name: '担保类型',
          code: 'guaranteeType',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '抵质押类',
              val: 1,
            },
            {
              name: '信用类',
              val: 2,
            },
            {
              name: '担保类',
              val: 3,
            },
            {
              name: '组合类',
              val: 4,
            }
          ]
        },
        {
          name: '贷款期限',
          code: 'loanLength',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '6个月内(含)',
              val: 1,
            },
            {
              name: '12个月内(含)',
              val: 2,
            },
            {
              name: '36个月内(含)',
              val: 3,
            },
            {
              name: '60个月内(含)',
              val: 4,
            },
            {
              name: '60个月以上',
              val: 5,
            }
          ]
        },
        {
          name: '贷款额度',
          code: 'loanLimit',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '10万以下(含)',
              val: 1,
            },
            {
              name: '10-20万(含)',
              val: 2,
            },
            {
              name: '20-50万(含)',
              val: 3,
            },
            {
              name: '50-100万(含)',
              val: 4,
            },
            {
              name: '100-500万(含)',
              val: 5,
            },
            {
              name: '500万以上',
              val: 6,
            }
          ]
        }
      ],
      sortConfig: [
        {
          name: '发布时间',
          code: 1,
        },
        {
          name: '最高额度',
          code: 2,
        },
        {
          name: '贷款期限',
          code: 3,
        },
      ]
    }
  },
  computed: {
  },
  mounted() {
    // typeList[0].val  获取机构名称
    this.$api.technologyFinance.institution().then((res) => {
      const data = res.data.data || [];
      data.forEach((item) => {
        item.name = item.institutionName
        item.val = item.id
      })
      this.typeList[0].val = [...[{
        name: '全部',
        val: null,
      }], ...data]
    })
    this.handleSearch()
  },
  methods: {
    getList() {
      let params = Object.assign(this.page, this.queryInfo)
      this.$api.technologyFinance.loanList(params).then((res) => {
        let data = res.data;
        this.dataList = data.rows
        this.total = data.total
      })
    },
    handleSearch(params) {
      // 发起请求
      this.queryInfo = params
      // this.queryInfo.keywords = params.keywords
      this.getList();
    },
    handleDetailClick(id) {
      this.$router.push({ path: "/technologyFinance/loanDetail", query: { id: id } });
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-search {
  background: #fff;
  padding: 0 40px;
}
.pro-list {
  background: #fff;
  margin-top: 20px;
  padding: 30px;
  .item {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0;
    .product {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      height: 150px;
      border: 1px solid #ececec;
      align-items: center;
      .img {
        width: 197px;
        height: 73px;
      }
      .info {
        flex: 0.8;
        display: flex;
        flex-direction: column;
        .title {
          display: flex;
          flex-direction: row;
          .type {
            font-weight: 700;
            padding: 10px 20px;
          }
          .name {
            font-size: 14px;
            font-weight: 200;
            padding: 10px 20px;
          }
          .icon {
            font-size: 12px;
            color: white;
            padding: 2px 10px;
            border: 2px solid #f1af53;
            background-color: #f1af53;
            height: 15px;
            border-radius: 0 10px 0 10px;
          }
        }

        .desc {
          display: flex;
          flex-direction: row;
          padding: 10px 20px;
          justify-content: space-around;
          .desc-item {
            display: flex;
            flex-direction: column;
            width: 20%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .info-text {
              font-size: 14px;
              font-weight: 200;
            }
          }
        }
      }
      .more {
        display: inline-block;
        background-color: #f1af53;
        color: #fff;
        padding: 8px 15px;
        border-radius: 15px;
        margin: 10px 20px 20px;
        cursor: pointer;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #0046c0;
    }
  }
}
</style>
